

(function($) {

    let width = 0,
        height = 0,
        scrollTop = 0

    let moveBenefits = function() {
        let $dest = $('.section-benefits'),
            $source = $('.section-benefitsCMS'),
            content = $source.html()

        if ($dest.length > 0 && $source.length > 0 && content !== '') {
            $dest.html( content )
            $source.remove()
        }
    }

    let onResize = function() {
        width = window.innerWidth
        height = window.innerHeight
    }

    let onScroll = function() {
        let prevScroll = scrollTop
        scrollTop = $(window).scrollTop()

        if (prevScroll < scrollTop && scrollTop > 20 && !$('.header').hasClass('active')) {
            $('.header').addClass('moveTop')
        } else {
            $('.header').removeClass('moveTop')
        }
    }
    
    let setEvents = function() {
        $(window).on('resize', function() {
            onResize()
        })

        $(window).on('scroll', function() {
            onScroll()
        })
    }
    
    let init = function() {
        setEvents()
        moveBenefits()
    }

    init();

    console.log('i am here...')
    
})($);